import { useEffect, useState } from 'react';
import { pdf } from '@react-pdf/renderer';
import { message } from 'antd';
import MantencionDocument from './MantencionDocument';
import axios from 'axios';
import { getAccessTokenApi } from 'api/auth';
import { API_PATH } from 'config';
import { getOrderConfigApi } from 'api/budget';

const useGenerateAndSendPDF = () => {
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState({});

  const token = getAccessTokenApi();
  useEffect(() => {
    getOrderConfigApi(token).then((result) => {
      setConfig(result);
    });
  }, [token]);
  const generateAndSendPDF = async (mantencion, staticData, email, id) => {
    const { Ascensores, mantencion: mantencionData } = mantencion;
    setLoading(true);
    try {
      const doc = (
        <MantencionDocument
          Ascensores={Ascensores}
          mantencion={mantencionData}
          config={config}
          actividades={mantencion.actividades}
          staticData={staticData}
        />
      );
      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();

      // Envía el PDF a la API
      const formData = new FormData();
      formData.append(
        'file',
        blob,
        `Informe de mantencion N°${mantencionData.id}.pdf`
      );
      formData.append('email', email);
      formData.append('id', mantencionData.id);
      const token = getAccessTokenApi();

      const response = await axios.post(
        `${API_PATH}/mantenciones/send-mantencion`,
        formData,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      //   await axios sendMantencionPDF(formData);
      message.success('Documento enviado exitosamente');
    } catch (error) {
      console.log(error);
      message.error('Error al enviar el documento');
    } finally {
      setLoading(false);
    }
  };

  return { generateAndSendPDF, loading };
};

export default useGenerateAndSendPDF;
