import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React from 'react';

import { useMaintanceToDo } from 'providers/MaintanceToDoProvider';
import Signature from 'components/global/Form/Signature';
import { useNavigate } from 'react-router-dom';
import { useSolicitudesMantencion } from '../../providers/SolicitudesMantencionProvider';

function FinishMaintanceForm({ handleModal, idMantencion }) {
  const [form] = useForm();
  const { handleFinishForm } = useMaintanceToDo();
  const navigate = useNavigate();
  const { handleSendPDF } = useSolicitudesMantencion();
  const submit = async () => {
    try {
      const values = form.getFieldsValue();
      await handleFinishForm(values);
      if (handleModal) {
        handleModal(true);
      }
      await handleSendPDF({
        id_mantencion: idMantencion,
      });
      return navigate('../specialist/');
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Form
        form={form}
        onFinish={submit}
        requiredMark={false}
        initialValues={{ id_mantencion: idMantencion }}
      >
        <Form.Item
          name="id_mantencion"
          label="Nombre del recepcionista"
          hidden
          labelCol={{ span: 24 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="name_recep"
          label="Nombre del recepcionista"
          rules={[
            {
              required: true,
              message: 'Debe ingresar el nombre de quien recibe el comprobante',
            },
          ]}
          labelCol={{ span: 24 }}
        >
          <Input placeholder="Ingresar nombre de recepcionista" />
        </Form.Item>
        <Form.Item
          label="Cargo de recepción"
          name="cargo_recep"
          labelCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: 'El cargo del recepcionista es obligatorio',
            },
          ]}
        >
          <Input placeholder="Cargo de quien recibe" />
        </Form.Item>
        <Form.Item
          label="Firma recepcionista"
          name="firma_recep"
          rules={[
            {
              required: true,
              message: 'La firma del recepcionista es obligatoria',
            },
          ]}
          labelCol={{ span: 24 }}
        >
          <Signature form={form} />
        </Form.Item>
        <Form.Item
          label="Firma Especialista"
          name="firma_spec"
          rules={[
            {
              required: true,
              message: 'La firma del especialista es obligatoria',
            },
          ]}
          labelCol={{ span: 24 }}
        >
          <Signature form={form} />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary">
            Finalizar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default FinishMaintanceForm;
